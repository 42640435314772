import { css, cx } from "@linaria/core";
import { navigate } from "gatsby";
import { useState, useMemo } from "react";
import { Alert, AlertProps as RBAlertProps } from "react-bootstrap";
import { useForm } from "react-yup";

import { Meta, Layout, Section } from "~/ui";
import { CONTACT_SCHEMA, Form } from "~/components";
import { Routes } from "~/models";

import { ReactComponent as UserIcon } from "~/assets/icons/user.svg";
import { ReactComponent as EmailIcon } from "~/assets/icons/envelope.svg";
import contactImg from "~/assets/images/contact.png";

interface AlertProps extends RBAlertProps {
  children: React.ReactNode;
}

const functionURL =
  process.env.GATSBY_FUNCTION_URL ||
  "https://cyan-buffalo-8576.twil.io/yvol-send-email-prod-env";

export default function Contact(): JSX.Element {
  const {
    field,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    createSubmitHandler,
  } = useForm({
    validationSchema: CONTACT_SCHEMA,
    defaultValues: {
      data: "",
      environment: process.env.GATSBY_ENVIRONMENT || "",
    },
  });

  const [alertState, setAlertState] = useState<AlertProps>({
    variant: "danger",
    show: false,
    children: null,
  });

  const handleSubmit = useMemo(() => {
    return createSubmitHandler((v) => {
      fetch(functionURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: new URLSearchParams(v).toString(),
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Unhandled network error");
          }
          navigate("/message-sent");
        })
        .catch((err) => {
          setSubmitting(false);
          setAlertState((prev) => ({
            ...prev,
            variant: "danger",
            show: true,
            children:
              "There was an issue processing your request, please try again later.",
          }));
        });
    });
  }, [createSubmitHandler, setAlertState, setSubmitting]);

  return (
    <Layout backgroundColor="primary">
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-7 mb-4">
            <h1 className="d-flex flex-column text-primary mb-4">
              <span>Have</span>
              <span className="display-1">Questions?</span>
            </h1>
            <p className="lead mb-5">
              If you have any questions, please contact us using the form below:
            </p>
            {!isSubmitting && alertState.show && (
              <div className="px-3 px-sm-5">
                <Alert {...alertState} />
              </div>
            )}
            <form
              id="yvol-contact-page-form"
              method="post"
              className={cx(
                "ms-md-5 px-lg-5 mb-4",
                css`
                  max-width: 500px;
                `
              )}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                name="environment"
                value={process.env.GATSBY_ENVIRONMENT || ""}
              />
              <input type="hidden" name="data" value="" />
              <Form.Field
                id="name"
                name="name"
                type="text"
                label="Your name"
                icon={<UserIcon width={20} height={20} />}
                labelProps={{ className: "visually-hidden" }}
                placeholder="Your name"
                value={values.name || ""}
                touched={!!touched.name}
                error={errors.name || ""}
                {...field}
                onChange={field.onChange}
              />
              <Form.Field
                id="email"
                name="email"
                type="email"
                icon={<EmailIcon width={20} height={20} />}
                label="Your email address"
                labelProps={{ className: "visually-hidden" }}
                placeholder="Your email"
                value={values.email || ""}
                touched={!!touched.email}
                error={errors.email || ""}
                {...field}
              />
              <Form.TextArea
                id="message"
                name="message"
                rows={6}
                label="Your message"
                labelProps={{ className: "visually-hidden" }}
                placeholder="Your message"
                value={values.message || ""}
                touched={!!touched.message}
                error={errors.message || ""}
                {...field}
              />
              <button
                type="submit"
                className="btn btn-success shadow-sm"
                disabled={
                  !(
                    values.name &&
                    !errors.name &&
                    values.email &&
                    !errors.email &&
                    values.message &&
                    !errors.message
                  ) || isSubmitting
                }
              >
                {isSubmitting ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
          <div className="col-12 col-lg-5 text-center mb-4">
            <img
              src={contactImg}
              alt="People talking"
              className="img-fluid mt-lg-n5"
              width={364}
              height={590}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Contact" url={Routes.Contact} />;
};
